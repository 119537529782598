import React,{Component} from 'react';
import './index.scss'
import Logo from './../../assets/images/tata-sky-logo-black.png';
import Login from './Login';
import Register from "./Register";
// import ReferOffer from "./ReferOffer";

class Auth extends Component {
    state = {
        activeTab : 0,
      userInputObj: {}
    };

    handleSelect = (index, obj) => {
        this.setState({
            activeTab: index,
          userInputObj: obj
        })
    };

    componentDidMount() {
        const { location } = this.props;
        if (location && location.state && location.state.fromChangePassword) {
            this.setState({ activeTab: 1 });
        }
    }

    render() {
        const { activeTab, userInputObj } = this.state;
        return (
            <div className="auth-container">
                <div className="wrapper">
                    <img src={Logo} alt="Tata Play" className="logo mb10" />
                    <div className="row mrl0">
                        <div className="col-12 col-xl-5 prl0">
                            <div className="form-container ptb20">
                                <ul className="list-tab mb20">
                                    <li
                                        className={activeTab === 0 ? 'active' : ''}
                                        onClick={() =>this.handleSelect(0)}
                                    >
                                        Login
                                    </li>
                                    <li
                                        className={activeTab === 1 ? 'active' : ''}
                                        onClick={() =>this.handleSelect(1)}
                                    >
                                        Register
                                    </li>
                                </ul>
                                { activeTab === 0 ? (
                                      <Login handleSelect={this.handleSelect}/>
                                    ) :<Register userInputObj={userInputObj} handleSelect={this.handleSelect}/>
                                }
                            </div>
                        </div>
                        {/*<div className="col-12 col-xl-7">
                            <ReferOffer />
                        </div>*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default Auth;