import React from 'react';
import {NavLink} from 'react-router-dom';
import {PATHS} from "../../../../constants";
import {getLocalStorage} from '../../../../services/commonUtils';

import './Navbar.scss';

const Navbar = () => {
    // const [userDropdown, setUserDropdown] = useState(false)
    const userType = getLocalStorage('admin');

    if(userType && userType.role === 'admin' ){
        return (
            <ul className="navbar">
                <li className="option">
                    <NavLink to={PATHS.ADMIN_DASHBOARD} exact={true}>
                        Dashboard
                    </NavLink>
                </li>
                <li className="option">
                    <NavLink to={PATHS.ADMIN_ADD_USER} exact={true}>
                        Add New User
                    </NavLink>
                </li>
                </ul>
        )
    }

    if(userType && userType.role === 'sales' ){
        return (
            <ul className="navbar">
                <li className="option">
                    <NavLink to={PATHS.ADMIN_DASHBOARD} exact={true}>
                        Change Password
                    </NavLink>
                </li>
             </ul>
        )
    }

   
    return (
        <ul className="navbar">
            <li className="option">
                <NavLink className="option-link" to={PATHS.HOME} exact={true}>
                    Dashboard
                </NavLink>
            </li>

            <li className="option">
                <NavLink className="option-link" to={PATHS.ESCALATION_MATRIX}>
                    Escalation Matrix
                </NavLink>
            </li>

            {/* <li className="option" title="Coming Soon...">
                <span className="disabled-link">
                    Bulk Recharge
                </span>
            </li> */}

            {/* <li className="option">
                <NavLink to={PATHS.BULK_RECHARGE}>
                    Bulk Recharge
                </NavLink>
            </li> */}

            <li className='option' >
                <NavLink className="option-link" to={`${PATHS.BULK_MODIFY}`}>
                    Bulk Modify
                </NavLink>
                <ul className="user-dropdown">
                    <li className="option-menu">
                
                            {/* <Link to={PATHS.PROFILE}>
                                My Profile
                            </Link> */}
                <NavLink to={`${PATHS.BULK_MODIFY}`}>Bulk Pack Modify</NavLink>
                        </li>
                        <li className="option-menu">
                            <NavLink to={`${PATHS.BULK_STATUS_UPDATE}`}>Bulk Status Update</NavLink>
            </li>
                    </ul>
            </li>

            <li className="option">
                <NavLink className="option-link" to={PATHS.ACTIVITIES}>
                    Recent Activities {/*Activities*/}
                </NavLink>
            </li>

            <li className="option">
                <NavLink className="option-link" to={PATHS.SERVICE_REQUEST}>
                    Bill/Recharge Query
                </NavLink>
            </li>

            <li className="option">
                <NavLink className="option-link" to={PATHS.GET_NEW_CON}>
                    Get a new connection
                </NavLink>
            </li>
        </ul>
    )
};


export default Navbar;