import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from "redux";
import { closeModal, openModal as openModalHandler } from "../../../../storeManager/modal/actions";
// import InputsValidator from '../../../../services/FormValidator/InputsValidator';
import { commonService } from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import Loader from "../../../Loader";
import { PATHS } from "../../../../constants";
import Error from "../../../Common/Error";
import { MODALS } from "../../../../storeManager/modal/constants";
import { restrictDecimalInput } from './../../../../helper';
import ReCaptcha from '../../../Common/GoogleCaptcha/googleCaptcha';
import resendOtpImg from '../../../../assets/images/resend-otp-new.svg'
import '../login.css'
import { aesEncrypt, interceptedData } from '../../../../services/commonUtils';

class LoginOTP extends Component {
    state = {
        rmn: '',
        emailId: '',
        otp: '',
        error: '',
        password: '',
        openForgotPasswordControl: false,
        isLoading: false,
        isSendingOtp: false,
        resendOtp: true,
        showPasswordField: true,
        captchaToken: null,
        rmnHeader: false,
        showSuccessTest: false,
    };

    componentDidMount() {
        const { modalParameters: {otpForRegister} } = this.props;
        if(otpForRegister){
            this.setState({showPasswordField: false})
            this.generateOtpForRMN();
        } 
        this.inputFocus.focus();
    }

    otpInputChangeHandler = (event) => {
        let { name, value } = event.target;
        value = value.trim();
        if (name === 'otp') {
            if (!isNaN(value)) {
                this.setState({
                    [name]: value,
                    error: ''
                })
            }
        } else {
            this.setState({
                [name]: value,
                error: ''
            })
        }
    };
    validateOTP = async () => {
        const { otp } = this.state;
        const { modalParameters: { chainId, userName }, history, closeModal } = this.props;
        const payload = {
            "validateOTP": {
                "otp": otp,
                "subscriberId": chainId, //used to create token
            }
        };
        try {
            const response = await commonService.validateOTPStatus(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                const userNameKey = responseData.data.name || userName;
                const ipAddress = btoa(responseData.data.ipAddress);
                const lastLoginTime = !responseData.data.lastLoginTime ? 0 : responseData.data.lastLoginTime;
                const name = btoa(userNameKey);
                const userChainId = btoa(chainId);
                localStorage.setItem("key", `${name}`);
                localStorage.setItem("chKeyId", `${userChainId}`);
                localStorage.setItem("ipAddress", `${ipAddress}`);
                localStorage.setItem("lastLoginTime", `${lastLoginTime}`);
                closeModal();
                history.push(PATHS.HOME);
            } else {
                this.setState({ error: responseData.message, isLoading: false })
            }
        } catch {
            this.setState({ error: GlobalConfig.serverError, isLoading: false })
        }
    };

    validatePassword = async (chainId) => {
        const { password, captchaToken } = this.state;
        const { history, closeModal, modalParameters: { handleSelect } } = this.props;
        const payload = {
            "subscriberId": chainId,
            "password": aesEncrypt(password),
            "captchaToken": captchaToken
        };
        try {
            const response = await commonService.validatePasswordStatus(payload);
            const responseData = interceptedData(response.data);
            
            this.captchaElement && this.captchaElement.reset(this.captchaElementResetId);
    
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                const userName = btoa(responseData.data.name);
                const userChainId = btoa(chainId);
                const ipAddress = btoa(responseData.data.ipAddress);
                const lastLoginTime = !responseData.data.lastLoginTime ? 0 : responseData.data.lastLoginTime;
                localStorage.setItem("key", `${userName}`);
                localStorage.setItem("chKeyId", `${userChainId}`);
                localStorage.setItem("ipAddress", `${ipAddress}`);
                localStorage.setItem("lastLoginTime", `${lastLoginTime}`);
                closeModal();
                history.push({
                    pathname: PATHS.HOME,
                });
            } else if (responseData.status === GlobalConfig.MESSAGES_TYPES.FALSE) {
                if (responseData.data && responseData.data.register === true) {
                    this.setState({ error: responseData.message, isLoading: false });
                    setTimeout(() => {
                        closeModal();
                        handleSelect(1);
                    }, 1000);
                } else {
                    this.setState({ error: responseData.message, isLoading: false });
                }
            } else {
                this.setState({ error: responseData.message, isLoading: false });
            }
        } catch {
            this.captchaElement && this.captchaElement.reset(this.captchaElementResetId);
            this.setState({ error: GlobalConfig.serverError, isLoading: false });
        }
    };

    sendEmail = async (chainId) => {
        const { captchaToken } = this.state;
        const { openModal } = this.props;
        const payload = {
            "subscriberId": chainId,
            "captchaToken": captchaToken
        };
        try {
            const response = await commonService.sendEmailStatus(payload);
            const responseData = response.data;
            this.captchaElement &&
                this.captchaElement.reset(this.captchaElementResetId);
            openModal(MODALS.CONFIRMATION_POPUP,
                { message: responseData.message, redirectLoginPage: true, hideCloseButton: true, forgotpassData: responseData.data,})
        } catch {
            this.captchaElement &&
                this.captchaElement.reset(this.captchaElementResetId);
            this.setState({ error: GlobalConfig.serverError, isLoading: false })
        }
    };

    toggleState = (key) => () => {
        this.setState(prevState => ({
            [key]: !prevState[key],
            error: '',
            showPasswordField: false
        }));
    };

    generateOtpForRMN = async () => {
        const { modalParameters: { chainId } } = this.props;
        this.setState({ error: '', isSendingOtp: true, otp: '' });
        const payload = {
            "generateOTP": {
                "subscriberId": chainId
            }
        };
        try {
            const response = await commonService.generateOtp(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({ isSendingOtp: false, rmn: responseData.data.rmn, showPasswordField: false, rmnHeader: true, showSuccessTest: true }) //resendOtp: responseData.data.resendOtp
                this.startOtpTimer()
            } else {
                this.setState({ error: responseData.message, isSendingOtp: false })
            }
        } catch {
            this.setState({ error: GlobalConfig.serverError, isSendingOtp: false })
        }

    };

    verifyUser = (e) => {
        e.preventDefault();
        const { openForgotPasswordControl, showPasswordField, otp } = this.state;
        const { modalParameters : {otpForRegister, registeredUser, chainId}, closeModal} = this.props;
        this.setState({ isLoading: true });
        const payload = {
                "otp": otp,
                "subscriberId": chainId,
        };
        // const {modalParameters: {chainId}} = this.props;
        if (openForgotPasswordControl || showPasswordField) {
            this.onValidateCaptcha();
            // this.sendEmail(chainId);
        } /*else if(showPasswordField){
        this.onValidateCaptcha();
        // this.validatePassword(chainId);
      }*/ else {
        if(otpForRegister){
            this.setState({isLoading: false});
            closeModal();
            registeredUser(payload);
        }
        else this.validateOTP();
        }
    };

    onCaptchaResolved = captchaToken => {
        const { modalParameters: { chainId } } = this.props;
        const { showPasswordField } = this.state;
        this.setState({ captchaToken });
        if (captchaToken) {
            showPasswordField ? this.validatePassword(chainId) : this.sendEmail(chainId);
        }

    };

    onValidateCaptcha = () => {
        // e.preventDefault();
        this.captchaElement.execute(); //this.captchaElementResetId
        // this.validateField();
        // let isValidData = this.validateForm();
        /*if (isValidData && this.captchaElement) {
            this.captchaElement.execute(this.captchaElementResetId);
        }*/
    };
    startOtpTimer = () => {
        let timer = 30;
        let Interval;
        const timerElement = document.querySelector('#timer_div');
        const resendElement = document.querySelector('.link-contr');
        const resendTimer = document.querySelector('.res-otp');
        resendElement.style.display = 'none';
        resendTimer.style.display = 'block';
        Interval = setInterval(() => {
            if (timer === 0 && resendElement && resendTimer) {
                clearTimeout(Interval);
                resendElement.style.display = 'block';
                resendTimer.style.display = 'none';
            } else {
                timer = timer < 10 ? `0${timer}` : timer;
                if (timerElement) {
                    resendTimer.style.display = 'block';
                    timerElement.innerHTML = timer;
                }
                timer--;
            }
        }, 1000);
        // clearTimeout(Interval);
    }
    render() {
        const { otp, error, password, openForgotPasswordControl, isLoading, resendOtp, showPasswordField, rmn, rmnHeader, isSendingOtp, showSuccessTest} = this.state;
        const { otpInputChangeHandler, verifyUser, toggleState, generateOtpForRMN } = this;
        const {modalParameters : {otpForRegister} } = this.props;

        const OTPLink = (isSendingOtp ? <span className="lds-hourglass ptb15"> Sending</span>
            :
            <Fragment>
                <div className="link-contr text-btn " onClick={generateOtpForRMN}>
                    <img src={resendOtpImg} alt="call" title="call " style={{ width: '15px', marginRight: '5px' }} />
                    Resend OTP
                </div>
                <div className=" res-otp field-info-otp " style={{ display: 'none' }}>
                    Resend OTP in 00:
                    <span id="timer_div" />
                </div>
            </Fragment>
        );

        return (
            <Fragment>
                {
                    (isLoading || isSendingOtp) && <Loader />
                }
                <div className="b2b-modal-header center pb10">
                    <div className="gradient-text mT20">
                        <div className='mb20'>
                            {
                                openForgotPasswordControl ? 'Forgot Password?' :
                                    showPasswordField ? 'Enter Your Password' : 'Enter Your OTP'
                            }
                        </div>
                    </div>
                    {rmnHeader && (<div className="rmn-field">
                        <span className='rmnColor'> Enter OTP sent to </span> {rmn}
                    </div>)}
                </div>

                <div className="prl50">
                    <form onSubmit={(e) => verifyUser(e)}>
                        {
                            showPasswordField ? (
                                <Fragment>
                                <div className='optmodal-element'>
                                  <div className="form-element mt5 ">
                                    <input type="password"
                                           className="optmodal-input-element input-element"
                                           placeholder="Password"
                                           name="password"
                                           value={password}
                                           onChange={otpInputChangeHandler}
                                           ref={(input) => {
                                             this.inputFocus = input;
                                           }}
                                           autoComplete="off"
                                    />
                                  </div>
                                  <div className="mb10 text-right" >
                                    Or <span className="text-btn" onClick={generateOtpForRMN}>login using OTP</span> 
                                  </div>
                                    <ReCaptcha
                                        id="recaptcha"
                                        getRef={ref => {
                                            this.captchaElement = ref;
                                        }}
                                        onChange={this.onCaptchaResolved}
                                        resetId={id => {
                                            this.captchaElementResetId = id;
                                        }}
                                    />
                                    </div>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {
                                        openForgotPasswordControl ? (
                                            <Fragment>
                                                <ReCaptcha
                                                    id="recaptcha-forget-password"
                                                    getRef={ref => {
                                                        this.captchaElement = ref;
                                                    }}
                                                    onChange={this.onCaptchaResolved}
                                                    resetId={id => {
                                                        this.captchaElementResetId = id;
                                                    }}
                                                />
                                                <p className='otplogin-description'>
                                                    Please click proceed to receive password reset link on your registered email id and mobile number.
                                                </p>
                                                {/*<p>
                                                    Your password will be reset and sent to the e-mail ID registered with your Subscriber ID.
                                                </p>*/}
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <div className="form-element">
                                                    <input type="text"
                                                        className="input-element otp-pwd"
                                                        placeholder="OTP"
                                                        maxLength="6"
                                                        name="otp"
                                                        value={otp}
                                                        onChange={otpInputChangeHandler}
                                                        ref={(input) => {
                                                            this.inputFocus = input;
                                                        }}
                                                        autoComplete="off"
                                                        onKeyPress={restrictDecimalInput}
                                                    />
                                                </div>
                                                {resendOtp ? OTPLink : null}
                                            </Fragment>
                                        )
                                    }
                                </Fragment>
                            )
                        }
                        {<span
                            className={`${!openForgotPasswordControl && !showPasswordField && !isSendingOtp ? 'fadeout' : ''} success-text`}
                            style={otpForRegister && !showSuccessTest ? {visibility:"hidden"} : {}}
                        >
                            OTP sent successfully
                        </span>}
                        {
                            error && <Error error={error} />
                        }

                        <button
                            type="submit"
                            className="button fill big w-140 mt10 mb20"
                            onClick={(e) => verifyUser(e)}
                            disabled={
                                showPasswordField ?
                                    (!password) : openForgotPasswordControl ? false : (!otp || otp.length < 6)
                            }
                        >
                            {
                                openForgotPasswordControl ? 'Proceed' : 'Verify'
                            }
                        </button>
                        {showPasswordField &&
                            <div className='pb20'>Forgot your password?
                                <span className="justify-content-end text-btn"
                                    onClick={toggleState("openForgotPasswordControl")}>
                                    &nbsp;Click here
                                </span>
                            </div>}
                    </form>
                </div>
            </Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: bindActionCreators(closeModal, dispatch),
        openModal: bindActionCreators(openModalHandler, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(LoginOTP));
