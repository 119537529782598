import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './modal.scss';
import {MODALS} from "../../../storeManager/modal/constants";
import LoginOTP from './../../Auth/Login/LoginOTP';
import MultipleChainId from "../../Auth/Login/MultipleChainId";
import BalanceDetailsModal from "./../../Dashboard/Connection/BalanceDetailsModal";
import ConfirmationPopup from "./../../TemporarilySuspend/ConfirmationPopup";
import {closeModal} from '../../../storeManager/modal/actions'
import ActivityDetailModal from "../../Activities/ActivityDetailModal";
import ChangePasswordModal from "../../MyProfile/ChangePasswordModal";
import AdminOTPModal from "../../Admin/AdminLogin/Login/AdminOTPModal";
import OTPModal from "../../Admin/AdminDashboard/SalesUser/OTPModal";
import AdminEditUserModal from "../../Admin/AdminDashboard/AdminEditUserModal";
import BulkSuspendModal from '../../Dashboard/Modal/bulkSuspendModal';

const mapStateToProps = state => ({
  modal: state.modalReducer,
});

const mapDispatchToProps = dispatch => ({
  closePopup: bindActionCreators(closeModal, dispatch),
});

class Modal extends Component {
  showContent = () => {
    const { closePopup, modal: { modalName, modalParameters }, history } = this.props;
    if (modalName) {
      switch (modalName) {
        case MODALS.LOGIN_OTP:
          return <LoginOTP
            closePopup={closePopup}
            modalParameters={modalParameters}
          />;
        case MODALS.BALANCE_DETAILS:
          return <BalanceDetailsModal closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.MULTIPLE_CHAIN_ID:
          return <MultipleChainId closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.CONFIRMATION_POPUP:
          return <ConfirmationPopup closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.ACTIVITIES_DETAILS:
          return <ActivityDetailModal closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.CHANGE_PASSWORD:
          return <ChangePasswordModal closePopup={closePopup} modalParameters={modalParameters} history={history}/>;
        case MODALS.ADMIN_OTP_MODAL:
          return <AdminOTPModal closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.OTP_MODAL:
          return <OTPModal closePopup={closePopup} modalParameters={modalParameters} history={history}/>;  
        case MODALS.ADMIN_EDIT_USER:
          return <AdminEditUserModal closePopup={closePopup} modalParameters={modalParameters}/>;
        case MODALS.BULK_SUSPEND_MODAL:
          return <BulkSuspendModal closePopup={closePopup} modalParameters={modalParameters}/>;
  
          default:
          return null
      }
    }
  };

  render() {
    const { modal: { showModal, modalParameters }, closePopup } = this.props;
    const content = this.showContent();
    return (
      <Fragment>
        {
          showModal ? (
              <div className="b2b-modal show">
                <div className="modal-dialog">
                  <div
                      className={
                        modalParameters.mW600 ?
                            "b2b-modal-content mW600" : "b2b-modal-content"
                      }
                  >
                    {content}
                    {
                      modalParameters.hideCloseButton ? null : (
                          <i className="close-icon" onClick={() => closePopup()}>
                            <svg width="17" height="17">
                              <title>Close</title>
                              <path fill="#67757d" fillRule="evenodd"
                                    d="M16.803 15.197c-.012.465-.221.818-.64 1.019-.431.207-.872.177-1.227-.15-.587-.542-1.145-1.116-1.712-1.68a1413.788 1413.788 0 0 1-4.242-4.242c-.137-.137-.208-.129-.34.004-1.91 1.915-3.827 3.825-5.741 5.738-.272.271-.569.463-.973.445-.455-.019-.791-.233-.987-.634-.201-.407-.163-.812.106-1.184.076-.106.171-.199.263-.292l5.692-5.683c.182-.182.182-.182.005-.359-1.911-1.907-3.82-3.816-5.734-5.719C.89 2.079.707 1.653.894 1.124A1.136 1.136 0 0 1 2.672.625c.349.284.648.623.966.941 1.67 1.663 3.336 3.328 5.003 4.992.172.172.173.172.347-.002 1.911-1.907 3.822-3.813 5.731-5.723.275-.275.577-.472.988-.451.456.023.789.24.983.641.194.402.158.802-.104 1.169-.079.111-.179.209-.276.306a10168.58 10168.58 0 0 1-5.692 5.683c-.176.175-.176.176-.005.346 1.91 1.907 3.82 3.815 5.733 5.72.266.265.468.553.457.95z"/>
                            </svg>
                          </i>
                      )
                    }
                  </div>
                </div>
              </div>
          ) : null
        }
      </Fragment>
    )
  }
}

Modal.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
