import React, {Fragment, Component} from 'react';
import Loader from "../../Loader";
import {debounce, get} from "lodash";
import InputsValidator from "../../../services/FormValidator/InputsValidator";
import Tooltip from "../../Common/Tooltip";
import {INPUT_ERROR, PATHS} from "../../../constants";
import {commonService} from "../../../storeManager/common/services";
import GlobalConfig from "../../../globalConfig/globalConfig";
import {withRouter} from 'react-router-dom';
import {MODALS} from "../../../storeManager/modal/constants";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {openModal as openModalHandler, closeModal} from "../../../storeManager/modal/actions";
import Error from "../../Common/Error";

class ChangePasswordModal extends Component {
    updateError = null;
    state = {
        password: '',
        newPassword: '',
        confirmNewPassword: '',
        error: {},
        isLoading: false,
        responseMsg: ''
    };

    componentDidMount() {
        this.firstInput.focus();
    }

    changePasswordHandler = (event) => {
        const {error} = this.state;
        this.setState({responseMsg: ""});
        let {name, value} = event.target;
        const updateState = {[name] : value};
        this.setState(updateState, () => {
            get(this.updateError, 'cancel') && this.updateError.cancel();
            this.updateError = debounce(() => {
                const validateError = this.validateFields(name, value);
                this.setState({
                    error: {
                        ...error,
                        [(validateError && validateError.name) || name]: validateError
                    }
                });
            }, 100);
            this.updateError();
        });
    };

    validateFields = (name) => {
        const {
            [name]: validateKey,
            newPassword,
            confirmNewPassword
        } = this.state;

        switch (name) {
            case 'newPassword':
                /*if (confirmNewPassword) {
                    return InputsValidator.validateConfirmPassword(validateKey, confirmNewPassword) || {};
                }
                return InputsValidator.validatePassword(validateKey) || {};*/
                return InputsValidator.validatePassword(validateKey, confirmNewPassword, "confirmNewPassword") || {};
            case 'confirmNewPassword':
                /*if (newPassword) {
                    return InputsValidator.validateConfirmPassword(validateKey, newPassword) || {};
                }
                return InputsValidator.validatePassword(validateKey) || {};*/
                return InputsValidator.validateConfirmPassword(validateKey, newPassword) || {};
            default:
                return null;
        }
    };

    changePasswordSubmit = async (event) => {
        const { history, openModal, closeModal } = this.props;
        event.preventDefault();
        const { password, newPassword, confirmNewPassword } = this.state;
        this.setState({ isLoading: true });
    
        const payload = {
            password: password,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword,
        };

        try {
            const response = await commonService.submitChangePassword(payload);
            const responseData = response.data;
    
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({ isLoading: false });
                openModal(MODALS.CONFIRMATION_POPUP,
                    {
                        message: responseData.message || "Change Password Successfully",
                        hideCloseButton: true,
                    });
            } else if (responseData.status === GlobalConfig.MESSAGES_TYPES.FALSE) {
                if (responseData.data && responseData.data.register === true) {
                    this.setState({ error: responseData.message, isLoading: false });
                    setTimeout(() => {
                        localStorage.clear();
                        history.push({ pathname: PATHS.LOGIN, state: { fromChangePassword: true } });
                        closeModal();
                    }, 1000);
                } else {
                    this.setState({ responseMsg: responseData.message, isLoading: false });
                }
            } else {
                this.setState({ responseMsg: responseData.message, isLoading: false });
            }
        } catch {
            this.setState({ error: GlobalConfig.serverError, isLoading: false });
        }
    };

    render() {
        const {
            isLoading,
            password,
            newPassword,
            confirmNewPassword,
            error,
            responseMsg
        } = this.state;

        const {changePasswordHandler, changePasswordSubmit} = this;

        const isEnabled =
            (get(error, 'newPassword.error') || !newPassword) ||
            (get(error, 'confirmNewPassword.error') || !confirmNewPassword);

        return (
            <Fragment>
                {
                    isLoading && <Loader />
                }
                <div className="b2b-modal-header center pb30">
                    <div className="gradient-text">
                        Change Password
                    </div>
                </div>

                <form onSubmit={(e) => changePasswordSubmit(e)}>
                    <div className="form-element mb25">
                        <input
                            type="password"
                            className="input-element"
                            placeholder="Password"
                            name="password"
                            value={password}
                            onChange={changePasswordHandler}
                            autoComplete="off"
                            ref={(input) => {
                                this.firstInput = input;
                            }}
                        />
                    </div>

                    <div className={get(error, 'newPassword.error') ? "form-element mb25 error" : "form-element mb25"}>
                        <input
                            type="password"
                            className="input-element"
                            placeholder="New Password"
                            name="newPassword"
                            value={newPassword}
                            onChange={changePasswordHandler}
                            autoComplete="off"
                        />
                        <i className="info-icon">
                            <svg width="20" height="20" viewBox="0 0 28 28">
                                <path fill="#CCC" fillRule="evenodd"
                                      d="M13.515.014C6.059.014.014 6.059.014 13.514c0 7.457 6.045 13.502 13.501 13.502 7.456 0 13.501-6.045 13.501-13.502 0-7.455-6.045-13.5-13.501-13.5zm2.81 20.924c-.694.274-1.248.482-1.663.626-.413.144-.894.217-1.442.217-.842 0-1.496-.208-1.963-.616-.466-.411-.698-.931-.698-1.563 0-.246.017-.498.051-.753.036-.256.092-.544.168-.868l.87-3.071c.077-.297.143-.577.195-.838.053-.263.078-.504.078-.723 0-.391-.081-.666-.242-.82-.163-.154-.471-.23-.929-.23-.224 0-.455.033-.692.103a9.249 9.249 0 0 0-.605.202l.23-.947a21.55 21.55 0 0 1 1.635-.595 4.825 4.825 0 0 1 1.475-.25c.835 0 1.479.204 1.934.606.451.404.679.928.679 1.573 0 .134-.016.369-.048.705a4.735 4.735 0 0 1-.174.928l-.865 3.063c-.07.246-.134.527-.19.84a4.36 4.36 0 0 0-.084.718c0 .405.09.683.273.832.181.147.498.222.944.222.212 0 .449-.038.717-.113.266-.073.458-.139.578-.194l-.232.946zm-.153-12.433a2.068 2.068 0 0 1-1.458.562 2.09 2.09 0 0 1-1.462-.562c-.405-.375-.61-.831-.61-1.364 0-.531.206-.988.61-1.367a2.073 2.073 0 0 1 1.462-.568c.569 0 1.055.189 1.458.568.403.379.606.836.606 1.367 0 .534-.203.989-.606 1.364z"/>
                            </svg>
                        </i>
                        <Tooltip
                            message={INPUT_ERROR.PATTERN_PASSWORD}
                        />
                        {
                            get(error, 'newPassword.error') ? (
                                <div className="error-section">
                                    <i className="icon">
                                        <svg width="20" height="20" viewBox="0 0 25 25">
                                            <defs>
                                                <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                                                    <stop offset="0%" stopColor="#A63283"/>
                                                    <stop offset="100%" stopColor="#EA1B2D"/>
                                                </linearGradient>
                                            </defs>
                                            <path fill="#51B684" fillRule="evenodd"
                                                  d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                            <path fill="url(#error)"
                                                  d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                        </svg>
                                    </i>
                                    <div className="error-message">
                                        {get(error, 'newPassword.message')}
                                    </div>
                                </div>
                            ) : ''
                        }
                    </div>

                    <div
                        className={get(error, 'confirmNewPassword.error') ? "form-element mb25 error" : "form-element mb25"}>
                        <input
                            type="password"
                            className="input-element"
                            placeholder="Confirm Password"
                            name="confirmNewPassword"
                            value={confirmNewPassword}
                            onChange={changePasswordHandler}
                            autoComplete="off"
                        />

                        {
                            get(error, 'confirmNewPassword.error') ? (
                                <div className="error-section">
                                    <i className="icon">
                                        <svg width="20" height="20" viewBox="0 0 25 25">
                                            <defs>
                                                <linearGradient id="error" x1="100%" x2="0%" y1="0%" y2="0%">
                                                    <stop offset="0%" stopColor="#A63283"/>
                                                    <stop offset="100%" stopColor="#EA1B2D"/>
                                                </linearGradient>
                                            </defs>
                                            <path fill="#51B684" fillRule="evenodd"
                                                  d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                            <path fill="url(#error)"
                                                  d="M24.799 22.646c-.018.7-.332 1.23-.961 1.532-.647.311-1.308.266-1.839-.225-.881-.815-1.719-1.677-2.569-2.525-2.124-2.122-4.249-4.243-6.364-6.374-.205-.206-.311-.194-.509.005-2.866 2.879-5.742 5.748-8.612 8.623-.407.408-.854.695-1.459.669-.684-.03-1.186-.351-1.481-.952-.301-.612-.245-1.222.159-1.781.115-.158.256-.299.395-.438 2.845-2.847 5.692-5.693 8.538-8.538.273-.274.273-.274.007-.54-2.866-2.866-5.729-5.735-8.601-8.595C.928 2.935.654 2.296.935 1.501 1.322.407 2.702.016 3.601.75c.525.427.972.937 1.45 1.414 2.504 2.499 5.003 5.001 7.504 7.502.258.258.259.259.52-.003 2.866-2.866 5.734-5.73 8.597-8.599.414-.415.866-.71 1.483-.679.683.035 1.182.361 1.474.964.291.603.236 1.205-.157 1.757-.119.166-.268.314-.413.459-2.845 2.847-5.692 5.693-8.538 8.539-.264.264-.263.264-.008.52 2.866 2.866 5.731 5.732 8.6 8.595.398.398.702.832.686 1.427z"/>
                                        </svg>
                                    </i>
                                    <div className="error-message">
                                        {get(error, 'confirmNewPassword.message')}
                                    </div>
                                </div>
                            ) : ''
                        }
                    </div>

                    {
                        responseMsg && <Error error={responseMsg}/>
                    }

                    <button
                        type="submit"
                        className="button fill full big mt15"
                        disabled={isEnabled}
                    >
                        Submit
                    </button>
                </form>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        openModal: bindActionCreators(openModalHandler, dispatch),
        closeModal: bindActionCreators(closeModal, dispatch),
    }
};

export default connect(null, mapDispatchToProps)(withRouter(ChangePasswordModal));
